import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReduser from "../reducer";
const middlewares = [thunk];
const store = configureStore({
  reducer: rootReduser,
  middlewares: composeWithDevTools(applyMiddleware(...middlewares)),
});

export default store;
