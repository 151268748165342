import {
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_TEAM_BY_CLIENT_REQUEST,
  GET_TEAM_BY_CLIENT_SUCCESS,
  GET_TEAM_BY_CLIENT_FAIL,
} from "../actionTypes/ClientActionTypes";

const initialState = {
  loading: false,
  action: "CLIENT",
  client: [],
  teambyclient: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

export const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload.data.list,
        message: action.message,
      };

    case GET_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GET_TEAM_BY_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        teambyclient: action.payload.data.list,
        message: action.message,
      };

    case GET_TEAM_BY_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};
