export const GETALL_LEAVE_REQUEST = "GETALL_LEAVE_REQUEST";
export const GETALL_LEAVE_SUCCESS = "GETALL_LEAVE_SUCCESS";
export const GETALL_LEAVE_FAIL = "GETALL_LEAVE_FAIL";

export const GETALL_APPROVE_LEAVE_REQUEST = "GETALL_APPROVE_LEAVE_REQUEST";
export const GETALL_APPROVE_LEAVE_SUCCESS = "GETALL_APPROVE_LEAVE_SUCCESS";
export const GETALL_APPROVE_LEAVE_FAIL = "GETALL_APPROVE_LEAVE_FAIL";
export const CLEAR_USER_RECORD = "CLEAR_USER_RECORD";

export const GETALL_LEAVE_BALANCE_REQUEST = "GETALL_LEAVE_BALANCE_REQUEST";
export const GETALL_LEAVE_BALANCE_SUCCESS = "GETALL_LEAVE_BALANCE_SUCCESS";
export const GETALL_LEAVE_BALANCE_FAIL = "GETALL_LEAVE_BALANCE_FAIL";

export const LEAVE_GETBYID_REQUEST = "LEAVE_GETBYID_REQUEST";
export const LEAVE_GETBYID_SUCCESS = "LEAVE_GETBYID_SUCCESS";
export const LEAVE_GETBYID_FAIL = "LEAVE_GETBYID_FAIL";

export const GET_LEAVE_REQUEST = "GET_LEAVE_REQUEST";
export const GET_LEAVE_SUCCESS = "GET_LEAVE_SUCCESS";
export const GET_LEAVE_FAIL = "GET_LEAVE_FAIL";

export const CANCEL_LEAVE_REQUEST = "CANCEL_LEAVE_REQUEST";
export const CANCEL_LEAVE_SUCCESS = "CANCEL_LEAVE_SUCCESS";
export const CANCEL_LEAVE_FAIL = "CANCEL_LEAVE_FAIL";

export const ADD_LEAVE_REQUEST = "ADD_LEAVE_REQUEST";
export const ADD_LEAVE_SUCCESS = "ADD_LEAVE_SUCCESS";
export const ADD_LEAVE_FAIL = "ADD_LEAVE_FAIL";

export const ADD_LEAVE_APPROVE_REQUEST = "ADD_LEAVE_APPROVE_REQUEST";
export const ADD_LEAVE_APPROVE_SUCCESS = "ADD_LEAVE_APPROVE_SUCCESS";
export const ADD_LEAVE_APPROVE_FAIL = "ADD_LEAVE_APPROVE_FAIL";

export const GETALL_LEAVE_STATUS_REQUEST = "GETALL_LEAVE_STATUS_REQUEST";
export const GETALL_LEAVE_STATUS_SUCCESS = "GETALL_LEAVE_STATUS_SUCCESS";
export const GETALL_LEAVE_STATUS_FAIL = "GETALL_LEAVE_STATUS_FAIL";

export const GET_SINGLE_LEAVE_REQUEST = "GET_SINGLE_LEAVE_REQUEST";
export const GET_SINGLE_LEAVE_SUCCESS = "GET_SINGLE_LEAVE_SUCCESS";
export const GET_SINGLE_LEAVE_FAIL = "GET_SINGLE_LEAVE_FAIL"

export const UPDATE_SINGLE_LEAVE_REQUEST = "UPDATE_SINGLE_LEAVE_REQUEST";
export const UPDATE_SINGLE_LEAVE_SUCCESS = "UPDATE_SINGLE_LEAVE_SUCCESS";
export const UPDATE_SINGLE_LEAVE_FAIL = "UPDATE_SINGLE_LEAVE_FAIL";