import { combineReducers } from "redux";

import { AuthReducer } from "./AuthReducer";
import { GenderReducer } from "./GenderReducer";
import { ProfileReducer } from "./ProfileReducer";
import { LeaveReducer } from "./LeaveReducer";
import { ActivityReducer } from "./ActivityReducer";
import { ClientReducer } from "./ClientReducer";
import { ProjectReducer } from "./ProjectReducer";
import { ScopeReducer } from "./ScopeReducer";
import { TimeSheetReducer } from "./TimeSheetReducer";
import { TeamsReducer } from "./TeamsReducer";
import { EmployeeReducer } from "./EmployeeReducer";

const rootReduser = combineReducers({
  Authentication: AuthReducer,
  Profile: ProfileReducer,
  Gender: GenderReducer,
  Leave: LeaveReducer,
  Activity: ActivityReducer,
  Client: ClientReducer,
  Project: ProjectReducer,
  Scope: ScopeReducer,
  TimeSheet: TimeSheetReducer,
  Teams: TeamsReducer,
  Employee: EmployeeReducer,
});
export default rootReduser;
