import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Component/Leave/Table.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-notifications/lib/notifications.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
