import {
  GETALL_LEAVE_REQUEST,
  GETALL_LEAVE_SUCCESS,
  GETALL_LEAVE_FAIL,
  CANCEL_LEAVE_REQUEST,
  CANCEL_LEAVE_SUCCESS,
  CANCEL_LEAVE_FAIL,
  GETALL_APPROVE_LEAVE_REQUEST,
  GETALL_APPROVE_LEAVE_SUCCESS,
  GETALL_APPROVE_LEAVE_FAIL,
  GETALL_LEAVE_BALANCE_REQUEST,
  GETALL_LEAVE_BALANCE_SUCCESS,
  GETALL_LEAVE_BALANCE_FAIL,
  ADD_LEAVE_REQUEST,
  ADD_LEAVE_SUCCESS,
  ADD_LEAVE_FAIL,
  GET_LEAVE_REQUEST,
  GET_LEAVE_SUCCESS,
  GET_LEAVE_FAIL,
  LEAVE_GETBYID_REQUEST,
  LEAVE_GETBYID_SUCCESS,
  LEAVE_GETBYID_FAIL,
  ADD_LEAVE_APPROVE_FAIL,
  ADD_LEAVE_APPROVE_SUCCESS,
  ADD_LEAVE_APPROVE_REQUEST,
  GETALL_LEAVE_STATUS_FAIL,
  GETALL_LEAVE_STATUS_SUCCESS,
  GETALL_LEAVE_STATUS_REQUEST,
  GET_SINGLE_LEAVE_REQUEST,
  GET_SINGLE_LEAVE_SUCCESS,
  GET_SINGLE_LEAVE_FAIL,
  UPDATE_SINGLE_LEAVE_REQUEST,
  UPDATE_SINGLE_LEAVE_SUCCESS,
  UPDATE_SINGLE_LEAVE_FAIL,
  CLEAR_USER_RECORD,
} from "../actionTypes/LeaveActionTypes";
const initialState = {
  loading: false,
  isloading: false,
  lodings: false,
  action: "Leave",
  result: [],
  results: [],
  resultleavebalance: [],
  resultaproveleave: [],
  leavetstatus: [],
  success: false,
  singledata: [],
  items: [],
  singleItems: [],
  message: "",
  error: "",
};
export const LeaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.data.list,
        message: action.message,
      };
    case GETALL_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GETALL_LEAVE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_LEAVE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        leavetstatus: action.payload.data.list,
        message: action.message,
      };
    case GETALL_LEAVE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case GETALL_APPROVE_LEAVE_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case GETALL_APPROVE_LEAVE_SUCCESS:
      return {
        ...state,
        isloading: false,
        resultaproveleave: action.payload.data.list,
        message: action.message,
      };
    case GETALL_APPROVE_LEAVE_FAIL:
      return {
        ...state,
        isloading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_USER_RECORD:
      return {
        ...state,
        isloading: false,
        message: action.message,
        result: [],
        results: [],
        resultleavebalance: [],
        resultaproveleave: [],
        leavetstatus: [],
        singledata: [],
        items: [],
        singleItems: [],
      };

    case GETALL_LEAVE_BALANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_LEAVE_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resultleavebalance: action.payload.data,
        message: action.message,
      };
    case GETALL_LEAVE_BALANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case GET_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GET_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    case LEAVE_GETBYID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LEAVE_GETBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        // items: action.payload.data,
        singleItems: action.payload.data,
        success: true,
        message: action.payload,
      };

    case LEAVE_GETBYID_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };

    case ADD_LEAVE_REQUEST:
      return {
        ...state,
        lodings: true,
      };
    case ADD_LEAVE_SUCCESS:
      return {
        ...state,
        lodings: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
        message: action.message,
      };
    case ADD_LEAVE_FAIL:
      return {
        ...state,
        lodings: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case ADD_LEAVE_APPROVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_LEAVE_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.filter((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),

        message: action.message,
      };
    case ADD_LEAVE_APPROVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case CANCEL_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
      };

    case CANCEL_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
    case GET_SINGLE_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        singledata: action.payload.data,
        message: action.payload,
      };
    case GET_SINGLE_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.payload,
      };

    case UPDATE_SINGLE_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SINGLE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: state.result.map((items) =>
          items.id === action.payload.id
            ? Object.assign({}, items, action.payload)
            : items
        ),
      };
    case UPDATE_SINGLE_LEAVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        message: action.payload,
      };
    default:
      return state;
  }
};
