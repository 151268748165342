import {
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAIL,
} from "../actionTypes/TeamsActionTyes";

const initialState = {
  loading: false,
  action: "TEAM",
  team: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

export const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        team: action.payload.data.list,
        message: action.message,
      };

    case GET_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};
