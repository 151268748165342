export const GET_TIMESHEET_REQUEST = "GET_TIMESHEET_REQUEST";
export const GET_TIMESHEET_SUCCESS = "GET_TIMESHEET_SUCCESS";
export const GET_TIMESHEET_FAIL = " GET_TIMESHEET_FAIL";

export const TIMESHEET_GETBYID_REQUEST = "TIMESHEET_GETBYID_REQUEST";
export const TIMESHEET_GETBYID_SUCCESS = "TIMESHEET_GETBYID_SUCCESS";
export const TIMESHEET_GETBYID_FAIL = " TIMESHEET_GETBYID_FAIL";

export const CREATE_TIMESHEET_REQUEST = "CREATE_TIMESHEET_REQUEST";
export const CREATE_TIMESHEET_SUCCESS = "CREATE_TIMESHEET_SUCCESS";
export const CREATE_TIMESHEET_FAIL = " CREATE_TIMESHEET_FAIL";

export const UPDATE_TIMESHEET_REQUEST = "UPDATE_TIMESHEET_REQUEST";
export const UPDATE_TIMESHEET_SUCCESS = "UPDATE_TIMESHEET_SUCCESS";
export const UPDATE_TIMESHEET_FAIL = " UPDATE_TIMESHEET_FAIL";

export const DELETE_TIMESHEET_REQUEST = "DELETE_TIMESHEET_REQUEST";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCCESS";
export const DELETE_TIMESHEET_FAIL = " DELETE_TIMESHEET_FAIL";

export const GET_USER_TIMELOGS_REQUEST = "GET_USER_TIMELOGS_REQUEST";
export const GET_USER_TIMELOGS_SUCCESS = "GET_USER_TIMELOGS_SUCCESS";
export const GET_USER_TIMELOGS_FAIL = " GET_USER_TIMELOGS_FAIL";

export const CLEAR_TIMESHEET_RECORD = " CLEAR_TIMESHEET_RECORD";
