import {
  GETALL_GENDER_FAIL,
  GETALL_GENDER_REQUEST,
  GETALL_GENDER_SUCCESS,
} from "../actionTypes/GenderActionTypes";

const initialState = {
  loading: false,
  action: "Gender",
  result: [],
  singledata: [],
  success: false,
  message: "",
  error: "",
};

export const GenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_GENDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GETALL_GENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };
    case GETALL_GENDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};
