import {
  CREATE_TIMESHEET_REQUEST,
  CREATE_TIMESHEET_SUCCESS,
  CREATE_TIMESHEET_FAIL,
  GET_TIMESHEET_REQUEST,
  GET_TIMESHEET_SUCCESS,
  GET_TIMESHEET_FAIL,
  GET_USER_TIMELOGS_REQUEST,
  GET_USER_TIMELOGS_SUCCESS,
  GET_USER_TIMELOGS_FAIL,
 CLEAR_TIMESHEET_RECORD
} from "../actionTypes/TimeSheetActionTypes";
const initialState = {
  loading: false,
  action: "TimeSheet",
  timesheet: [],
  timelogs: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};
export const TimeSheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIMESHEET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        timesheet: action.payload.data.list,
        message: action.message,
      };

    case GET_TIMESHEET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CREATE_TIMESHEET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
    

        message: action.message,
      };
    case CREATE_TIMESHEET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        message: action.message,
      };
      case GET_USER_TIMELOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_TIMELOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        usertimelogs: action.payload.data,
        message: action.message,
      };
    case GET_USER_TIMELOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case CLEAR_TIMESHEET_RECORD:
      return {
        ...state,
        loading: false,
        timesheet: [],
  timelogs: [],
      };
    default:
      return state;
  }
};
