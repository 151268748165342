import {
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAIL,
} from "../actionTypes/ActivityActionTypes";

const initialState = {
  loading: false,
  action: "ACTIVITY",
  result: [],
  success: false,
  singledata: [],
  message: "",
  error: "",
};

export const ActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data.list,
        message: action.message,
      };

    case GET_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };

    default:
      return state;
  }
};

