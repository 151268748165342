import {
  USER_EMAIL_PASSWORD_REQUEST,
  USER_EMAIL_PASSWORD_SUCCESS,
  USER_EMAIL_PASSWORD_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_CURRENT_USER,
  LOGOUT_USER,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_FORGET_PASSWORD_FAIL,
} from "../../Redux/actionTypes/AuthActionTypes";

const initialState = {
  loading: false,
  isLoggedIn: false,
  isSignUp: false,
  action: "Authentication",
  userInfo: {},
  singledata: [],
  success: false,
  message: "",
  error: false,
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        success: true,
        message: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userInfo: action.payload,
      };
    case USER_EMAIL_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_EMAIL_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        // result: state.result.concat[(action.payload)],
        message: action.message,
      };
    case USER_EMAIL_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message,
        error: action.error,
      };

    case SET_MESSAGE:
      return {
        message: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        message: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
      };
    case PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        success: true,
      };

    case PASSWORD_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };

    case USER_FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        success: true,
        result: state.result.concat(action.result.data),
      };
    case USER_FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };
    default:
      return state;
  }
};
