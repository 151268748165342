import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_PICTURE_UPDATE_REQUEST,
  PROFILE_PICTURE_UPDATE_SUCCESS,
  PROFILE_PICTURE_UPDATE_FAIL,
  PROFILE_GETBYID_REQUEST,
  PROFILE_GETBYID_SUCCESS,
  PROFILE_GETBYID_FAIL,
} from "../../Redux/actionTypes/ProfileActionTypes";

const initialState = {
  action: "Profile",
  loading: false,
  items: [],
  singleItems: [],
  message: "",
  error: false,
  success: false,
};
export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        success: true,
        message: action.payload,
      };

    case PROFILE_FAIL:
      return {
        loading: false,
        message: action.payload,
        error: true,
      };

    case PROFILE_GETBYID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_GETBYID_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        singleItems: action.payload.data,
        success: true,
        message: action.payload,
      };

    case PROFILE_GETBYID_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };

    case PROFILE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };
    case PROFILE_PICTURE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_PICTURE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload,
      };

    case PROFILE_PICTURE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: true,
      };
    default: {
      return state;
    }
  }
};
