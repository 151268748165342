import "./App.css";
import { Provider } from "react-redux";
import store from "./Redux/Store/store";
import { lazy, Suspense } from "react";
import { NotificationContainer } from "react-notifications";
import { DotLoaderOverlay } from "react-spinner-overlay";

const OtherComponent = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./AppRoutes")), 500);
  });
});
function App() {
  return (
    <>
      <Provider store={store}>
        <Suspense fallback={<DotLoaderOverlay />}>
          <OtherComponent />
          <NotificationContainer />
        </Suspense>
      </Provider>
    </>
  );
}

export default App;
