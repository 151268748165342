export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = " PROFILE_FAIL";

export const PROFILE_GETBYID_REQUEST = "PROFILE_GETBYID_REQUEST";
export const PROFILE_GETBYID_SUCCESS = "PROFILE_GETBYID_SUCCESS";
export const PROFILE_GETBYID_FAIL = " PROFILE_GETBYID_FAIL";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = " PROFILE_UPDATE_FAIL";

export const PROFILE_PICTURE_UPDATE_REQUEST = "PROFILE_PICTURE_UPDATE_REQUEST";
export const PROFILE_PICTURE_UPDATE_SUCCESS = "PROFILE_PICTURE_UPDATE_SUCCESS";
export const PROFILE_PICTURE_UPDATE_FAIL = " PROFILE_PICTURE_UPDATE_FAIL";
