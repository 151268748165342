export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = " LOGIN_FAIL";
export const SET_CURRENT_USER = " SET_CURRENT_USER";

export const USER_EMAIL_PASSWORD_REQUEST = "USER_EMAIL_PASSWORD_REQUEST";
export const USER_EMAIL_PASSWORD_SUCCESS = "USER_EMAIL_PASSWORD_SUCCESS";
export const USER_EMAIL_PASSWORD_FAIL = " USER_EMAIL_PASSWORD_FAIL";

export const SET_MESSAGE = " SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const LOGOUT_USER = " LOGOUT_USER";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";

export const USER_FORGET_PASSWORD_REQUEST = "USER_FORGET_PASSWORD_REQUEST";
export const USER_FORGET_PASSWORD_SUCCESS = "USER_FORGET_PASSWORD_SUCCESS";
export const USER_FORGET_PASSWORD_FAIL = "USER_FORGET_PASSWORD_FAIL";
export const LOGOUT_SESSION_TIMEOUT = "LOGOUT_SESSION_TIMEOUT";

